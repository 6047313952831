/* General styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  header {
    background-color: #005f99; /* Navy blue */
    color: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  header h1 {
    font-size: 1.5rem;
  }

  /* Logo Styling */
.logo {
    font-family: 'Great Vibes', cursive; /* Magical, elegant font */
    font-size: 3rem; /* Larger font size */
    font-weight: bold;
    color: white; /* Keeps the text white */
    text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3); /* Add depth with subtle shadow */
    margin: 0;
  }
  
  nav a {
    color: white;
    text-decoration: none;
    margin-left: 1rem;
    font-size: 1rem;
  }
  
  nav a:hover {
    text-decoration: underline;
  }
  
  /* Main content styles */
  main {
    padding: 2rem;
    background-color: #f9f9f9;
    min-height: calc(100vh - 120px); /* Adjust height for header and footer */
  }
  
  h2 {
    color: #005f99;
  }
  
  footer {
    background-color: #005f99;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
  }
  

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Space between fields */
    max-width: 500px;
    margin: 2rem auto; /* Center form horizontally and add top margin */
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    display: flex;
    flex-direction: column; /* Stack label and input */
    gap: 0.5rem; /* Space between label and input */
  }
  
  input, textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Ensures padding doesn't affect width */
  }
  
  textarea {
    height: 100px; /* Adjust height for message input */
    resize: vertical; /* Allow vertical resizing */
  }
  
  button {
    align-self: flex-start; /* Align button to the left */
    background-color: #005f99;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #004570;
  }
  