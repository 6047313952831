/* Contact Page Styling */
.contact-page {
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  padding: 2rem;
  text-align: center;
}

.contact-intro {
  margin-bottom: 2rem;
}

.contact-intro h2 {
  font-size: 2.5rem;
  color: #005f99;
  margin-bottom: 1rem;
}

.contact-intro p {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 700px;
  margin: 0 auto;
}
