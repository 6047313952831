/* Contact Form Styling */
.contact-form {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}

.contact-form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  color: #005f99;
  text-align: left; /* Ensures labels are left-aligned */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form textarea {
  height: 100px;
  resize: vertical;
}

.contact-form button {
  background-color: #005f99;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #ffba08;
  transform: scale(1.05);
}
