/* General Page Styling */
.about-page {
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  padding: 0;
  margin: 0;
}

/* Hero Section */
.about-hero img {
  width: 50%; /* Reduces the width to 20% of the container or viewport */
  height: 20%; /* Maintains aspect ratio */
  border-radius: 0 0 10px 10px; /* Optional: Adds rounded corners */
  margin: 0 auto; /* Centers the image horizontally */
  display: block; /* Ensures the centering works properly */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional: Adds a shadow for depth */
}

/* Intro Section */
.about-intro {
  text-align: center;
  padding: 2rem 1rem;
}

.about-intro h1 {
  font-size: 2.5rem;
  color: #005f99;
  margin-bottom: 1rem;
}

.about-intro p {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 700px;
  margin: 0 auto;
}

/* Founder Section */
.about-founder {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
}

.founder-image {
  flex: 1;
  max-width: 300px;
}

.founder-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.founder-bio {
  flex: 2;
  max-width: 600px;
}

/* Mobile Adjustments */
@media screen and (max-width: 768px) {
  .about-founder {
    flex-direction: column; /* Stacks the image and text vertically */
  }

  .founder-image {
    order: -1; /* Ensures the image appears above the text */
    max-width: 100%; /* Allows the image to take up more space */
  }

  .founder-bio {
    text-align: center; /* Optional: Centers text for better readability on mobile */
    margin-top: 1rem; /* Adds spacing between image and text */
  }
}


/* Call-to-Action */
.about-cta {
  text-align: center;
  margin-top: 2rem;
}

.cta-link {
  color: #005f99;
  text-decoration: underline;
  font-weight: bold;
}

.cta-link:hover {
  color: #ffba08;
  text-decoration: none;
}
