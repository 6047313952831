/* Hero Section */
.hero {
  background: linear-gradient(to right, #4facfe, #00f2fe);
  color: white;
  text-align: center;
  padding: 3rem 1rem;
  background-image: url('../../public/images/castle-bg.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: white;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.8); /* Adds a dark shadow around the text */
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  text-shadow: 3px 6px 6px rgba(0, 0, 0, 0.9); /* More pronounced shadow with increased spread and opacity */
  color: white; /* Ensures contrast against dark or busy backgrounds */
  font-size: 1.2rem; /* Slightly larger text for better visibility */
}


.cta-button {
  background-color: #08ceff; /* Bright blue */
  color: #ffffff; /* White text for better contrast */
  font-weight: bold; /* Make text more crisp */
  padding: 0.75rem 2rem;
  font-size: 1.1rem; /* Slightly larger font */
  border: none;
  border-radius: 8px; /* Slightly more rounded corners */
  text-decoration: none; /* Remove underline */
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;

  /* Add depth using box-shadow */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  transform: scale(1.05); /* Slight zoom effect */
  background-color: #002fff; /* Darker blue on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add shadow to text */
}


/* Services Section */
.services {
  padding: 2rem 1rem;
  text-align: center;
}

.service-cards {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.card h3 {
  font-size: 1.25rem;
  color: #005f99;
}

.card p {
  font-size: 1rem;
  color: #666;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
